
.container {

    position: relative;
    width: 390px;
    height: 961px;
    margin: 0 auto;

    background: #FFFFFF;
}

.titleContainer {
    position: absolute;
    width: 344.44px;
    height: 213.82px;
    left: 7px;
    top: 18px;
}

.content {
    font-family: system-ui, serif;
    font-size: 2rem;
    padding: 3rem;
    border-radius: 1rem;
    background: #ff6e6c;
}

.homeName {
    position: absolute;
    width: 344.44px;
    height: 213.82px;
    left: 7px;
    top: 71.94px;

    font-family: 'Mr Dafoe', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    line-height: 98px;

    text-align: center;
    letter-spacing: 0.075em;

    color: #000000;

}


.select {
    position: absolute;
    width: 326px;
    height: 95px;
    left: 17.22px;
    top: 350px;

    background: #FFFFFF;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}
.plane{
    position: absolute;
    width: 34px;
    height: 41px;
    left: 100px;
    top:30px;
}
.countryimage{
    position: absolute;
    width: 41px;
    height: 41px;
    left: 150px;
    top:30px;
}
.from {
    position: absolute;
    width: 89px;
    height: 38px;
    left: 5px;
    top: 6px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.1em;

    color: #000000;
}

.selectItem {
    position: absolute;
    width: 115px;
    height: 38px;
    left: 200px;
    top: 30px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.1em;

    color: #A2A2A2;
}

.imageHome {
    position: relative;
    width: 298px;
    height: 386px;
    left: 43px;
    top: 468px;


}

.bottomImage {
    position: relative;
    width: 298px;
    height: 386px;
    left: 33px;
    top: 468px;

    background: #D9D9D9;
    background-size: cover;
    background-image: url("/src/images/japanHome.jpg");
    border-radius: 20px;
}
.bottomDetail{
    top: 40px;
    left: 30px;
    margin: 0;
    position: relative;
    color: white;
}


.button {
    position: absolute;
    width: 80px;
    height: 18px;
    left: 200px;
    top: 350px;
    border: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.1em;
    background-color:rgba(0, 0, 0, 0);
}

.detailName {
    position: relative;
    width: 270px;
    height: 71px;
    left: 44.22px;
    top: 231.82px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -0.13em;

    color: #000000;
}