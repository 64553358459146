.container {

    position: relative;
    width: 390px;
    height: 961px;
    margin: 0 auto;

    background: #FFFFFF;
}

.map {
    position: relative;
    width: 390px;
    height: 350px;
    left: 0px;
    top: 80px;
}

.titleContainer {
    position: relative;
    width: 340.44px;
    height: 115.82px;
    left: 7px;
    top: 18px;
}

.homeName {
    position: relative;
    width: 180.44px;
    height: 115.82px;
    left: 7px;
    top: 50.94px;

    font-family: 'Mr Dafoe', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    line-height: 98px;

    text-align: center;
    letter-spacing: 0.075em;

    color: #000000;

}


.bottomSheet {
    position: relative;
    width: 400px;
    top: 100px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    background-color: white;
}

.listitem {

    margin: 0px; 
    border: 1px solid black;
    z-index: 1;
    text-align: center;
    height: auto;
    font-size: small;
    width: auto;
    border-radius: 30px;

}

.pagi{
    margin: 10px auto;
    text-align: center;
    font-weight: bold;
}

.click{
    background-color: yellow;
}

.listdiv{
    position: relative;
    width: 400px;
    top: 110px;
    font-weight: bold;
    z-index: 2;
}
.resultSheet{
    position: relative;
    width: 400px;
    top: 100px;
    font-size: 20px;
}